import React from 'react';

import Layout from '../components/layout';

import '../styles/training-simulation.css';

const TrainingSimulation = props => {
    return (
        <Layout location={props.location}>
            <div className='port-security-simulator-page'>
                <div className='port-security-simulator-page__top'>
                    <div className='port-security-simulator-page__top__left'>
                        <h1 className='port-security-simulator-page__top__left__title'>Training Simulation</h1>
                        <p className='port-security-simulator-page__top__left__detail'>
                            Companies across the world regularly use simulations as a tool to teach employees. Our training simulator solutions can be based on 2D and 3D graphics. They are configured and orchestrated using a Drag and Drop graphical user interface. 
We provide training simulations for different purposes. One example is port security staff training. The solutions can be used for planning the deployment of security staff, equipment and procedures, risk assessment and contingency planning for the risk scenarios.
                        </p>
                    </div>
                    <img className='port-security-simulator-page__top__image' src='/images/Modeling__Simulation.png' alt='port security simulator' />
                </div>
            </div>
        </Layout>
    );
}

export default TrainingSimulation;